
.form {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    padding: 20% 20%;
    text-align: center;
    z-index: 999999;
    background-color: #2a2a2a;
    
  }
    #progress-button {
    margin-bottom: 20px;
  }
  
  #progress-bar-status {
    display: inline-block;
  }
  
  .complete .dx-progressbar-range {
    background-color: green;
  }
  