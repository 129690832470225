@import url("../node_modules/jquery-ui/themes/base/all.css");
@import url("../node_modules/devextreme/dist/css/dx.dark.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.dark.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css");
@import url("../node_modules/devexpress-dashboard/dist/css/dx-dashboard.dark.css");

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.map-container {
  height: 790px;
  }
   
  .sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
  }


  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
    letter-spacing: 0px;
}



input, button{
    appearance:none;
    background:none;
    border:none;
    outline:none;
}



.App {
    height: 99vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #53565A;
}



form {
    display:block;
    position:relative;
}



   form:after {
        content: '';
        display: block;
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        z-index: 1;
        background-image: linear-gradient(to bottom right,#d5bee5,#7434a4);
    }



   form .form-inner{
        position:relative;
        display:block;
        background-color:#fff;
        padding:30px;
        z-index:2;
    }



   form .form-inner h2{
        color:#888;
        font-size:28px;
        font-weight:500;
        margin-bottom:30px;
    }



   form .form-inner .form-group{
        display:block;
        width:300px;
        margin-bottom:15px;
    }



   .form-inner .form-group label{
        display:flex;
        color:#666;
        font-size:12px;
        margin-bottom:5px;
        transition:0.4s;
        padding-left:4px;
    }



.form-inner .form-group:focus-within label {
    color: #8854b0;
}



form .form-inner .form-group input{
    display:block;
    width:100%;
    padding:10px 15px;
    background-color:#F8F8f8;
    border-radius:8px;
    transition:0.4s;
}



   form .form-inner .form-group input:focus{
        box-shadow:0px 0px 3px rgba(0,0,0,0.2);
    }



form .form-inner input[type="submit"] {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    background-image: linear-gradient(to right,#d5bee5 50%,#d5bee5 50%,#7434a4);
    background-size:200%;
    background-position:0%;
    transition:0.4s;
    color:#fff;
    font-weight:700;
    cursor:pointer;
}



   form .form-inner input[type="submit"]:hover{
        background-position:100% 0%;
    }

    .btn{
        border: 1px solid transparent;
        padding: 5px;
    border-radius: 0.25rem;
   cursor: pointer;
    }

    .btn-primary{
        color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    }