@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.menu-icon {
  width: 25px;
  height: 20px;
  position: absolute;
  z-index: 9999;
  overflow: hidden;
  top: 20px;
  left: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .2s ease-in-out;
  -moz-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  cursor: pointer;
}

.menu-icon.active {
  top: 5px;
  left: 115px;

}

.menu-icon span {
    display: none;
    position: absolute;
    height: 3px;
    width: 100%;
    background: rgba(222, 222, 222, 1);
    border-radius: 0;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  .menu-icon:hover span{
    display: block;
  }

  .menu-icon.active span{
    display: block;
  }

.menu-icon span:nth-child(1) {
  top: 0px;
}

.menu-icon span:nth-child(2),.menu-icon span:nth-child(3) {
  top: 8px;
}

.menu-icon span:nth-child(4) {
  top: 16px;
}

.menu-icon.active span:nth-child(1) {
  opacity: 0;
}

.menu-icon.active span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-icon.active span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-icon.active span:nth-child(4) {
opacity: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.hide-overlay{
  width: 0px !important;

}

.dx-datagrid{  
  font-size:12px ;  
}  


.drilldownGridStyle .dx-datagrid .dx-row > td{
  font-family: 'Open Sans', sans-serif;
  padding: 1.5px;
 
}

.dx-scrollview-content{
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
}

.drilldownGridFont{
  font-size: 10px
}

.dx-data-row {  
  user-select:text;  
}  
.dx-row-lines {  
  user-select:text!important;  
}


.displayInlineBlock{
  display: block;
}


/* comment grid style */

.flex {
  display: flex;
  align-items: center;
  /* margin: 10px 0;
  padding: 10px 20px; */
  background-color: transparent;
  /*border-radius: 4px;*/

}


.myRow {
  margin-bottom: 12px;
  margin-top: 12px;
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 4px;
}

.flexItem {
  width: 30%;
}


.formButton {
  margin-top: 10px;
  margin-left: 10px;
}


.yellow{
  background-color: #f9f9a2;
  color: black;
}


.green{
  background-color: #47e224;
  color: black;
}
.purple{
  background-color: #c1b3f2;
  color: black;
}

.blue{
  background-color: #38f1ff;
  color: black;
}

.pink{
  background-color: #f7c3c3;
  color: black;
}
/* .tooltip-header {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 1px solid #c5c5c5;
}

.tooltip-body {
  width: 160px;
} */

/* .tooltip-body .series-name {
  font-weight: normal;
  opacity: 0.6;
  display: inline-block;
  line-height: 1.5;
  padding-right: 10px;
  width: 126px;
}

.tooltip-body .value-text {
  display: inline-block;
  line-height: 1.5;
  width: 30px;
} */